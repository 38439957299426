<template>
  <div>
    <div
      class="modal right fade"
      :id="idModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog report-modal">
        <div class="modal-content">
          <div class="card-modal">
            <div class="modal-header">
              <h5 class="modal-title">Parameter Laporan</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3">
              <div class="modal-sub-title">Periode</div>
              <hr />

              <div class="form-group d-flex align-items-center">
                <label
                  for="inputAddress2"
                  class="form-label"
                  v-if="!isNeracaValue"
                  >Dari Periode
                </label>

                <label for="inputAddress2" class="form-label" v-else
                  >Tanggal
                </label>
                <div class="row w-100">
                  <div class="col-12">
                    <Datepicker
                      :autoApply="true"
                      class="w-100"
                      :closeOnScroll="true"
                      v-model="formModal.date_start"
                      placeholder="Tanggal Mulai"
                      :format="format"
                      :maxDate="new Date()"
                      :enableTimePicker="false"
                      locale="id-ID"
                      selectText="Pilih"
                      cancelText="Batal"
                      @update:modelValue="dateReport($event, 'date_start')"
                    ></Datepicker>
                  </div>
                </div>
              </div>
              <div class="form-group d-flex align-items-center">
                <label
                  for="inputAddress2"
                  class="form-label"
                  v-if="!isNeracaValue"
                  >S/d Periode
                </label>

                <label for="inputAddress2" class="form-label" v-else
                  >s/d Tanggal
                </label>
                <div class="row w-100">
                  <div class="col-12">
                    <Datepicker
                      :autoApply="true"
                      class="w-100"
                      :closeOnScroll="true"
                      v-model="formModal.date_end"
                      :minDate="formModal.date_start"
                      placeholder="Tanggal Selesai"
                      :format="format"
                      :maxDate="new Date()"
                      :enableTimePicker="false"
                      locale="id-ID"
                      selectText="Pilih"
                      cancelText="Batal"
                      @update:modelValue="dateReport($event, 'date_end')"
                    ></Datepicker>
                  </div>
                </div>
              </div>
              <div class="modal-sub-title" v-if="isDivisiValue || isHutangValue || isPiutangValue || isMergeValue || isNeracaValue || isLedger">Parameter Tambahan</div>
              <hr />
              <div
                class="form-group d-flex align-items-center mb-3"
                v-if="isLedger"
              >
                <label for="inputAddress2" class="form-label"
                  >Akun Perkiraan</label
                >
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_akun"
                  :options="optionAkun"
                  placeholder="Pilih Akun Perkiraan"
                  :settings="{
                    allowClear: true,
                    templateResult: formatState,
                    multiple: true,
                  }"
                />
              </div>
              <div
                class="form-group d-flex align-items-center mb-3"
                v-if="isHutangValue"
              >
                <label class="form-label"
                  >Vendor <span class="text-danger">*</span></label
                >
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_vendor"
                  :options="optionVendor"
                  required
                  placeholder="Pilih Vendor"
                />
              </div>
              <div
                class="form-group d-flex align-items-center mb-3"
                v-if="isPiutangValue"
              >
                <label class="form-label"
                  >Client <span class="text-danger">*</span></label
                >
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_client"
                  :options="optionClient"
                  required
                  placeholder="Pilih Client"
                />
              </div>
              <div
                class="form-group d-flex align-items-center mb-3"
                v-if="isDivisiValue && !isHutangValue && !isPiutangValue"
              >
                <label for="inputAddress2" class="form-label">Departemen</label>
                <Select2
                  style="width: 100%"
                  v-model="formModal.id_divisi"
                  :options="optionDepartement"
                  placeholder="Pilih Departement"
                  :settings="{
                    allowClear: true,
                  }"
                />
              </div>
              <div
                class="form-group"
                v-if="isMergeValue && !formModal.id_divisi"
              >
                <div class="clearfix">
                  <label for="form-label flaot-left"
                    >Gabungkan Nilai Akun Perkiraan Departemen</label
                  >
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.isAll"
                        :checked="formModal.isAll"
                        :value="true"
                        class="custom-control-input"
                        :id="`customall`"
                        @change="changeCheckbox('all')"
                      />
                      <label class="custom-control-label" :for="`customall`"
                        >Pilih Semua</label
                      >
                    </div>
                  </div>
                  <div
                    class="col-12"
                    v-for="(value, key) in optionDepartement"
                    :key="key"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.merge"
                        :checked="
                          formModal.merge.includes(value.id) ||
                          formModal.merge.includes(value.id.toString())
                        "
                        @change="changeCheckbox('item')"
                        :value="value.id"
                        class="custom-control-input"
                        :id="`customrange-${value.id}`"
                      />
                      <label
                        class="custom-control-label"
                        :for="`customrange-${value.id}`"
                        >{{ value.text }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="isOther">
                <div class="row">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formModal.isOther['show-zero']"
                        :checked="formModal.isOther['show-zero']"
                        :value="true"
                        class="custom-control-input"
                        :id="`showzerorange`"
                      />
                      <label class="custom-control-label" :for="`showzerorange`"
                        >Tampilkan data dengan nilai 0</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-save"
                style="width: unset"
                @click="showReport"
              >
                Tampilkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { v4 as uuid4 } from "uuid";
import { cksClient } from "../../../../helper";
import { ref } from "vue";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import moment from "moment";

export default {
  name: "modalRange",
  props: {
    show: Boolean,
    label: String,
    type: String,
    onPage: Boolean,
    departement: Object,
    parameter: Object,
    client: Object,
    vendor: Object,
    isMerge: Boolean,
    isNeraca: Boolean,
    isLedger: Boolean,
    isOther: Boolean,
    isDivisi: Boolean,
    isHutang: Boolean,
    isPiutang: Boolean,
    akun: Object,
  },

  components: {
    Datepicker,
    Select2,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      idModal: uuid4(),
      optionDepartement: this.$props.departement,
      optionClient: this.$props.client,
      optionVendor: this.$props.vendor,
      optionAkun: this.$props.akun,
      accessDepartement: cksClient().get("_account").akses_departement,
      isMergeValue: this.$props.isMerge ? this.$props.isMerge : false,
      isNeracaValue: this.$props.isNeraca ? this.$props.isNeraca : false,
      isHutangValue: this.$props.isHutang ? this.$props.isHutang : false,
      isPiutangValue: this.$props.isPiutang ? this.$props.isPiutang : false,
      isDivisiValue:
        this.$props.isDivisi === true || this.$props.isDivisi === false
          ? this.$props.isDivisi
          : true,
      formModal: {
        id_divisi:
          this.$props.parameter && this.$props.parameter.id_divisi
            ? this.$props.parameter.id_divisi
            : "",
        date_start:
          this.$props.parameter && this.$props.parameter.date_start
            ? this.$props.parameter.date_start
            : moment().format("YYYY-MM-DD"),
        date_end:
          this.$props.parameter && this.$props.parameter.date_end
            ? this.$props.parameter.date_end
            : moment().format("YYYY-MM-DD"),
        merge:
          this.$props.parameter && this.$props.parameter.merge
            ? this.$props.parameter.merge
            : [],
        isAll:
          this.$props.parameter && this.$props.parameter.isAll
            ? this.$props.parameter.isAll
            : false,
        id_akun:
          this.$props.parameter && this.$props.parameter.id_akun
            ? this.$props.parameter.id_akun
            : "",
        isOther:
          this.$props.parameter && this.$props.parameter.isOther
            ? this.$props.parameter.isOther
            : [],
        id_vendor:
          this.$props.parameter && this.$props.parameter.id_vendor
            ? this.$props.parameter.id_vendor
            : "",
        id_client:
          this.$props.parameter && this.$props.parameter.id_client
            ? this.$props.parameter.id_client
            : "",
      },
    };
  },
  
  methods: {
    showModal() {
      // this.formModal.date_start = "";
      // this.date_end = "";
      if (this.accessDepartement && !this.formModal.id_divisi) {
        var data = this.accessDepartement.split("|");
        this.formModal.id_divisi = data[0];
      } else {
        // this.formModal.id_divisi = "";
      }
      $("#" + this.idModal).modal("show");
      $("#" + this.idModal).on("hidden.bs.modal", () => {
        this.$emit("hide", false);
      });
    },
    dateReport(data, key) {
      this.formModal[key] = moment(data).format("YYYY-MM-DD");
      if (key == "date_start") {
        var first = moment(this.formModal.date_start).unix();
        var second = moment(this.formModal.date_end).unix();
        if (first > second) {
          this.formModal.date_end = "";
        }
      }
    },
    change() {},
    setHide() {
      this.showModalRange = false;
    },
    showReport() {
      if (this.isHutangValue && !this.formModal.id_vendor) {
        return true;
      }

      if (this.isPiutangValue && !this.formModal.id_client) {
        return true;
      }

      if (this.formModal.date_start && this.formModal.date_end) {
        $("#" + this.idModal).modal("hide");
        if (!this.$props.onPage) {
          var type = this.$props.type;
          if (type == "rincian_buku_besar") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "LedgerDetails",
              query: this.formModal,
            });
          } else if (type == "ringkasan_buku_besar") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "LedgerSummary",
              query: this.formModal,
            });
          } else if (type == "daftar_akun_perkiraan") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "ReportEstimatedAccount",
              query: this.formModal,
            });
          } else if (type == "jurnal_umum") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "GeneralJournalReport",
              query: this.formModal,
            });
          } else if (type == "profit_periode") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "PeriodComparison",
              query: this.formModal,
            });
          } else if (type == "neraca_periode") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "ComparasionPeriod",
              query: this.formModal,
            });
          } else if (type == "laba_rugi_project") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "ProfitLossProject",
              query: this.formModal,
            });
          } else if (type == "kartu_hutang") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "KartuHutang",
              query: this.formModal,
            });
          } else if (type == "kartu_piutang") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "KartuPiutang",
              query: this.formModal,
            });
          } else if (type == "summary_piutang") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "SummaryPiutang",
              query: this.formModal,
            });
          } else if (type == "summary_hutang") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "SummaryHutang",
              query: this.formModal,
            });
          } else if (type == "all_jurnal") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "AllJurnal",
              query: this.formModal,
            });
          } else if (type == "mutasi_persediaan") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "MutasiPersediaan",
              query: this.formModal,
            });
          } else if (type == "mutasi_inventory") {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$router.push({
              name: "MutasiInventory",
              query: this.formModal,
            });
          }
        } else {
          this.$emit("update", this.formModal);
        }
      }
    },

    changeCheckbox(type = "all") {
      if (type != "all") {
        var lengthAll = this.optionDepartement.length;
        var lengthMerge = this.formModal.merge.length;
        if (lengthAll > 0 && lengthAll == lengthMerge) {
          this.formModal.isAll = true;
        } else {
          this.formModal.isAll = false;
        }
      } else {
        this.formModal.merge = [];
        if (this.formModal.isAll) {
          for (const key in this.optionDepartement) {
            if (Object.hasOwnProperty.call(this.optionDepartement, key)) {
              const element = this.optionDepartement[key];
              this.formModal.merge.push(element.id);
            }
          }
        }
      }
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
  },

  watch: {
    show: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.showModal();
        }, 500);
      }
    },
    departement: function (newVal) {
      this.optionDepartement = newVal;
    },
    akun: function (newVal) {
      this.optionAkun = newVal;
    },
    parameter: function (newVal) {
      this.formModal.id_divisi = newVal.id_divisi ? newVal.id_divisi : "";
      this.formModal.date_start = newVal.date_start ? newVal.date_start : "";
      this.formModal.date_end = newVal.date_end ? newVal.date_end : "";
      this.formModal.merge = newVal.isMerge ? newVal.isMerge : [];
      this.formModal.id_akun = newVal.id_akun ? newVal.id_akun : "";
      this.formModal.isOther = newVal.isOther ? newVal.isOther : [];
      this.formModal.isAll = newVal.isAll ? newVal.isAll : false;
    },
    isMerge: function (newVal) {
      this.isMergeValue = newVal;
    },
    isNeraca: function (newVal) {
      this.isNeracaValue = newVal;
    },
    isDivisi: function (newVal) {
      console.log(newVal);
      this.isDivisiValue = newVal;
    },
    isHutang: function (newVal) {
      console.log(newVal);
      this.isHutangValue = newVal;
    },
    isPiutang: function (newVal) {
      this.isPiutangValue = newVal;
    },
    client: function (newVal) {
      this.optionClient = newVal;
    },
    vendor: function (newVal) {
      this.optionVendor = newVal;
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.text :nth-child(1) {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

#modalProfitLoss .modal-dialog {
  max-width: 700px;
}

#modalBalance .modal-dialog {
  max-width: 633px;
}

.text {
  margin-left: 10px;
}

.text :nth-child(2) {
  font-size: 10px;
  color: #898989;
}

.modal-sub-title {
  font-size: 15px;
  font-weight: 600;
}

.form-group .control-label,
.form-group > label {
  font-weight: 500;
}

.checkbox-text {
  font-weight: 500;
  font-size: 13px;
}
</style>
