import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";

const get_ReportSummaryJurnal = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.ALL_JURNAL, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportDebtCard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.DEBT_CARD, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportDebt = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.DEBT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportReceivableCard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.RECEIVABLE_CARD, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportReceivable = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.RECEIVABLE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportInventory = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.INVENTORY, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportDetailProject = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.SUMMARY.DETAIL_PROJECT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
    get_ReportSummaryJurnal,
    get_ReportDebtCard,
    get_ReportDebt,
    get_ReportReceivableCard,
    get_ReportReceivable,
    get_ReportInventory,
    get_ReportDetailProject
}